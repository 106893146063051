import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Your Web3Forms access key (you can find it on web3forms.com after registering)
const WEB3FORMS_ACCESS_KEY = "ea41c93f-1cd9-48ce-8657-6763ca1cf042";

export default function Popup({ open, setOpen, setStep }) {
    const initialFormState = {
        name: "",
        email: "",
        phone: "",
        comments: "",
    };

    const [form, setForm] = useState(initialFormState);
    const [result, setResult] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!form.name || !form.email || !form.phone || !form.comments) {
            toast.error("Please fill in all fields.");
            return;
        }

        setResult("Sending....");
        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("email", form.email);
        formData.append("phone", form.phone);
        formData.append("comments", form.comments);
        formData.append("access_key", WEB3FORMS_ACCESS_KEY);

        try {
            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();

            if (data.success) {
                setResult("Form Submitted Successfully");
                setForm(initialFormState);
            } else {
                toast.error(`Error: ${data.message}`);
                setResult(data.message);
            }
        } catch (error) {
            console.error("Error", error);
            toast.error("Something went wrong!");
            setResult("Something went wrong!");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    return (
        <div>
            <div
                className={`${open ? "flex" : "hidden"
                    } justify-center items-center duration-500 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[#00000066] h-full w-full`}
            >
                <div className="flex flex-col fixed z-50 justify-center gap-3 bg-white px-12 pt-12 pb-11 rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                    <input
                                        name="name"
                                        value={form.name}
                                        onChange={handleInputChange}
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Name :"
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                    <input
                                        name="email"
                                        value={form.email}
                                        onChange={handleInputChange}
                                        required
                                        type="email"
                                        className="form-control"
                                        placeholder="Email :"
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Your Mobile Number <span className="text-danger">*</span></label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={form.phone}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                        placeholder="Mobile No"
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Comments <span className="text-danger">*</span></label>
                                    <textarea
                                        name="comments"
                                        rows="4"
                                        value={form.comments}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                        placeholder="Message :"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="d-grid">
                                    <button
                                        type="submit"
                                        id="submit"
                                        name="send"
                                        disabled={!form.name || !form.email || !form.phone || !form.comments}
                                        className="btn btn-primary"
                                    >
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                    {/* Display result message */}
                    <span>{result}</span>
                </div>
            </div>
        </div>
    );
}
